<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card >
        <v-card-title>Edit Student</v-card-title>
        <v-card-text>
          <template>
            <FormInput
              :value="form['name']"
              label="Name"
              :rules="nameRules"
              @input="form['name'] = $event"
            />
          </template>

          <template>
            <FormInput
              :value="form['moeNumber']"
              label="MOE Number"
              @input="form['moeNumber'] = $event"
            />
          </template>
          <template>
            <FormSelect label="Gender" :data="gender" :value="form['gender']" @input="form['gender'] = $event"></FormSelect>
          </template>
          <template v-if="form.gender == 'other'">
            <FormInput
              :value="form['originalGender']"
              label="Original Gender"
              @input="form['originalGender'] = $event"
            />
          </template>
          <template>
            <FormSelect label="Race" :data="race" :value="form['race']" @input="form['race'] = $event"></FormSelect>
          </template>
          <template v-if="form.race == 'other'">
            <FormInput
              :value="form['originalRace']"
              label="Original Race"
              @input="form['originalRace'] = $event"
            />
          </template>
          <template>
            <FormRadio
              :value="form['isActive']"
              label="Active"
              @input="form['isActive'] = $event"
            />
          </template>
          <template>
            <FormRadio
              :value="form['isTransferred']"
              label="Transferred"
              @input="form['isTransferred'] = $event"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/Student/FormInput";
import FormSelect from "@/components/Moderator/Student/FormSelect";
import FormRadio from "@/components/Moderator/Student/FormRadio";
import datasetGender from '@/datasets/gender'
import datasetRace from '@/datasets/race'

import { mapState } from "vuex";
export default {
  props: ["data","self", "callbackSuccess"],
  components: {
    FormInput,
    FormSelect,
    FormRadio,
  },
  computed: mapState({
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    gender:datasetGender,
    race:datasetRace,
    //BOC:[form]
    form: {
      name: 'Kamal',
      moeNumber: '1234',
      gender: 'Male',
      race:'Indian',
      originalRace:'Indian',
      originalGender: 'Male',
      isActive: true,
      isTransferred: false
    },
    fields: [],
    rules: {},
    mobileRules: [],
    sortRules: [],
    keyRules: [],
    authorRules: [],
    nameRules: [],
    summaryRules:[],
    mobile: null,
    code: null,
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.sso}/api/v2/en/moderator/student/edit`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  mounted() {
    this.form.id = this.$route.params.id;
    this.form.name = this.self.name;
    this.form.moeNumber = this.self.moeNumber;
    this.form.gender = this.self.gender || null;
    this.form.originalGender = this.self.originalGender || null;
    this.form.originalRace = this.self.originalRace || null;
    this.form.race = this.self.race || null;
    this.form.isActive = this.self.isActive;
    this.form.isTransferred = this.self.isTransferred;
  },
  methods: {
   
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      this.nameRules = [(v) => !!v || "Name is required"];
     
      if (this.$refs.form.validate()) {
          this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
   submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
      this.api.isLoading = true;
  },
}
};
</script>

<style>
</style>